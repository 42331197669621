import React from 'react'
import PropTypes from 'prop-types'
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache, split } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { sha256 } from 'crypto-hash'

import { GRAPHQL_URL, SUBSCRIPTION_URL, TOAST_TYPE } from '@shared-submodule/sm-constants'
import { clearCookie } from '@shared-submodule/utils'
import { getToken } from '@shared/libs/token'
// import { history } from 'App'

const apiUrl = GRAPHQL_URL
const subscriptionUrl = SUBSCRIPTION_URL
const httpLink = createHttpLink({
  uri: apiUrl,
  useGETForQueries: true
})

const wsLink = () => {
  return new GraphQLWsLink(
    createClient({
      url: subscriptionUrl
    })
  )
}

const splitLink =
  typeof window === 'undefined' ? httpLink : split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink(),
    httpLink
  )

class MyApolloProvider extends React.Component {
  constructor(props) {
    super(props)
    this.middleware = setContext(({ operationName }, { headers }) => {
      if (!['GetPreviewArticleFront', 'GetPreviewFantasyArticleFront'].includes(operationName)) {
        const token = getToken()
        return {
          headers: {
            ...headers,
            authorization: token && `${token}`
          }
        }
      }
    })

    this.errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors && operation.operationName !== 'UserLogout') {
        graphQLErrors.forEach(({ message, extensions }) => {
          if (extensions?.code === 'UNAUTHENTICATED') {
            // unauthorized
            clearCookie()
            this.props?.router?.replace('/')
            // history.replace('/')
          }
          // if (message !== '502: Bad Gateway') {
          this.props.dispatch({
            type: 'SHOW_TOAST',
            payload: {
              message: "We're experiencing temporary issues. Please try again later.",
              type: TOAST_TYPE.Error
            }
          })
          // }
        })
      }
      if (networkError) console.error(`[Network error]: ${networkError}`)
    })
    this.graphqlClient = new ApolloClient({
      link: from([this.errorLink, this.middleware, createPersistedQueryLink({ sha256, useGETForHashedQueries: true }), splitLink]),
      defaultOptions: {
        watchQuery: { errorPolicy: 'all' },
        query: { errorPolicy: 'all' },
        mutate: { errorPolicy: 'all' }
      },
      cache: new InMemoryCache(),
      connectToDevTools: true
    })
  }

  render() {
    return <ApolloProvider client={this.graphqlClient}>{this.props.children}</ApolloProvider>
  }
}
MyApolloProvider.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  router: PropTypes.object,
  logout: PropTypes.func
}

export default MyApolloProvider
